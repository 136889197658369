import React from 'react'
import Icon1 from '../../images/img11.jpg'
import Icon2 from '../../images/img12.jpg'
import Icon3 from '../../images/img4.jpg'
import Icon4 from '../../images/img2.jpg'
import Icon5 from '../../images/img14.png'
import Icon6 from '../../images/img15.png'
import Icon7 from '../../images/img8.jpg'
import Icon8 from '../../images/img13.png'
import Icon9 from '../../images/img16.jpeg'
import Icon10 from '../../images/img17.jpg'


import Fondo from '../../images/blue.jpg'
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesP,  HeroBg, HeroContent, Bg, ServicesH2, } from './ServiceElements'

const Services = () => {

  return (
    <ServicesContainer id='services'>
      <HeroBg>
        <Bg src={Fondo}/>
      </HeroBg>
      <HeroContent>
      <ServicesH1>Nuestros servicios </ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>SOPORTE DE PRIMER Y SEGUNDO NIVEL</ServicesH2>
          <ServicesP>Estructuramos, según la necesidad, la mesa de ayuda de tecnología orientada a soportar eficientemente a los usuarios e infraestructura de nuestros clientes</ServicesP>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Icon2}/>
        <ServicesH2>SOPORTE POR DEMANDA</ServicesH2>
        <ServicesP>Proveemos los especialistas de soporte técnico según el tipo de problemática presentada en la infraestructura de nuestros clientes Nuestro personal se encuentra capacitado para brindar una atención y solución eficiente.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>MIGRACIÓN DE DATOS</ServicesH2>
          <ServicesP>Apoyamos los procesos de copias de seguridad y transferencia de datos bajo estrictos protocolos de ejecución, siempre orientados a minimizar la perdida información. Desarrollamos procesos que incrementan la productividad, mediante el uso de modelos eficientes orientados a maximizar los beneficios para la compañía.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4}/>
          <ServicesH2>SERVICIO DE NETWORKING</ServicesH2>
          <ServicesP>Estamos orientados a satisfacer las necesidades técnicas en diseño, instalación, mejora y soporte para las distintas redes del cliente; con calidad en el servicio, escalabilidad y eficiencia en la administración y el mantenimiento en general,orientados siempre a aumentar los beneficios para la compañía y sus colaboradores.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5}/>
          <ServicesH2>MANTENIMIENTO PREVENTIVO</ServicesH2>
          <ServicesP>Ejecutamos todas las actividades necesarias para la protección y prolongación de vida útil de sus equipos informáticos, mitigando el riesgo de daños y/o pérdida de la de la información.</ServicesP>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Icon6}/>
        <ServicesH2>MANTENIMIENTO CORRECTIVO</ServicesH2>
          <ServicesP>Contamos con el mejor talento, disponible a nivel nacional, para la asistencia de sus necesidades en sitio, y para el diagnóstico y la solución oportuna de las fallas de sus equipos tecnológicos. El mantenimiento correctivo hace referencia a la falla que presente un equipo, ésta puede ser de carácter físico o a nivel del software. Este mantenimiento se ejecuta con la finalidad de restablecer la operatividad del sistema o del equipo. La característica principal de este tipo de mantenimiento es reparar posibles daños, o en caso de ser necesario, remplazar en su totalidad los elementos de hardware y de software que puedan presentar fallas propias de los equipos ofimáticos.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon7}/>
          <ServicesH2>RENOVACIÓN TECNOLÓGICA</ServicesH2>
          <ServicesP>La renovación tecnológica es una solución que tiene como objetivo ofrecer a nuestros clientes una actualización constante y eficaz de sus equipos tecnológicos, con énfasis en la información y comunicaciones; incorporando así, todos los servicios necesarios para satisfacción total del mantenimiento. Para nosotros es importante brindar nuestro apoyo y experiencia en la renovación tecnológica de su compañía, con tiempos acordes a sus necesidades que les permitirán ser más eficientes y productivos.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon8}/>
          <ServicesH2>MANOS REMOTAS</ServicesH2>
          <ServicesP>Ofrecemos el apoyo de profesionales en la administración de sistemas e infraestructura,
            capacitados en la resolución de problemas técnicos, dificultades en la instalación de
            programas o en la configuración de los mismos; proporcionando así, el personal idóneo y
            los elementos necesarios para que desde su lugar de trabajo logre entender necesidades
            específicas sobre su infraestructura tecnológica que ayuden a optimizar la eficiencia
            empresarial y reducir los costos de operación asociados.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon9}/>
          <ServicesH2>SOPORTE A UPS</ServicesH2>
          <ServicesP>Realizamos diagnóstico, mantenimiento preventivo y correctivo de UPS, con el fin de mitigar y corregir  las fallas en estos dispositivos, manteniendo la estabilidad en el servicio. Contáctenos y evaluaremos su necesidad.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon10}/>
          <ServicesH2>EQUIPOS DE AIRES ACONDICIONADOS</ServicesH2>
          <ServicesP>Brindamos servicios de diseño de soluciones de enfriamiento, planeación e instalación de aires acondicionados que incluyen montajes de unidad interna y externa, mantenimiento preventivo como lavado interno y externo, verificación de funcionamiento y mantenimiento correctivo en el cual realizamos la revisión, diagnósticos, recarga de refrigerante, cambio de compresores, traslados de equipos, cambio de repuestos para los sectores industriales, hoteleros, comerciales, residenciales y de salud.<br/>

          Identificaremos la mejor solución de enfriamiento. Generamos cotizaciones acorde a la infraestructura y presupuesto de su compañía.<br/>

          Contamos con técnicos e ingenieros calificados y entrenados en UPS y en instalaciones de equipos de aires acondicionados.</ServicesP>
        </ServicesCard>

      </ServicesWrapper>

      </HeroContent>

    </ServicesContainer>
  )
}

export default Services
