import React from 'react'
import Icon1 from '../../images/img1.jpg'
import Icon2 from '../../images/img9.jpg'
import Icon3 from '../../images/img10.jpg'
import Fondo from '../../images/white.jpg'

import {ServicesContainer, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP, HeroBg, HeroContent, Bg, ServicesH3} from './ServiceElements'

const Pie = () => {
  return (
    <ServicesContainer id='pie'>
      <HeroBg>
        <Bg src={Fondo}/>
      </HeroBg>
      <HeroContent>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>Valores</ServicesH2>
          <ServicesP>A través de nuestros servicios trasmitimos:<ServicesH3> RESPONSABILIDAD<br/> CALIDAD<br/>CUMPLIMIENTO.<br/></ServicesH3>Alineados estrictamente con las necesidades de nuestros clientes y los compromisos adquiridos.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesH2>Puntualidad</ServicesH2>
          <ServicesP>Cada minuto es importante, por ello nuestra prioridad es atenderle en el menor tiempo posible, siendo más oportunos y eficientes. En la ejecución de nuestra labor cada minuto es importante, teniendo en cuenta que nuestra prioridad es clausurar en el menor tiempo posible la labor convenida.</ServicesP>
          <ServicesIcon src={Icon2}/>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>Trabajo en equipo</ServicesH2>
          <ServicesP>Garantizamos nuestro mayor esfuerzo en la consecución de objetivos y resultados que conlleven a la satisfacción plena del servicio. Garantizamos la gestión de equipos de trabajo con objetivos en común, estructurados y definidos en la búsqueda de favorecer de manera conjunta los resultados exigidos.</ServicesP>
        </ServicesCard>
        
      </ServicesWrapper>

      
      </HeroContent>

    </ServicesContainer>
  )
}

export default Pie
