import React, {useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavIcon, NavBtn, SocialIconLink} from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll';
import Icon1 from '../../images/top-page.png'
import { MdAttachEmail } from 'react-icons/md'




const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome} ><NavIcon src={Icon1}/></NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
          
          <NavItem>
              <NavLinks to='services'
              smooth={true} duration={1000} spy={true} exact='true' offset={-80}
              >Servicios</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='data'
              smooth={true} duration={1000} spy={true} exact='true' offset={-80}
              >¿Quienes somos?</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='experiencia'
              smooth={true} duration={1000} spy={true} exact='true' offset={-80}
              >Nuestros clientes</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='contacto'
              smooth={true} duration={1000} spy={true} exact='true' offset={-80}
              >Contacto</NavLinks>
            </NavItem>
            <NavBtn>
            <SocialIconLink href='https://micorreo.claro.com.co/#/mail/list/msg' target='_blank' arial-label='Linkedin'>
                  <MdAttachEmail />
            </SocialIconLink>          
            </NavBtn>
              
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
