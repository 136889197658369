import React from 'react'
import { Formul, InputSubmit, ServiceInput, ServiceText} from './ServiceElements'

const Formulario = () => {
  return (
    <Formul>
      <form action="https://formsubmit.co/Servicios@cts-ti.com " method="POST" >
      <ServiceInput type="text" name="fullname" placeholder='Nombre' required/>
      <ServiceInput type="email" name="email" placeholder='Email' required/>
      <ServiceInput type="number" name="phone" placeholder='Celular' required/>
      <ServiceText name="message" placeholder='Mensaje' required/>
      <InputSubmit type="submit" value='Enviar'/>

    <input type="hidden" name="_next" value="http://www.cts-ti.com"/>
    <input type="hidden" name="_captcha" value="false"/>

</form>
    </Formul>




  )
}

export default Formulario