import React, {useState} from 'react'
import Icon1 from '../../images/img7.jpg'
import Fondo from '../../images/white.jpg'
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesCard1, ServicesIcon, ServicesH2, ServicesP, HeroBg, HeroContent, Bg, HeroBtnWrapper, ArrowForward, ArrowRight} from './ServiceElements'
import { Button } from '../ButtonElements'


const Data = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }
  return (
    <ServicesContainer id='data'>
      <HeroBg>
        <Bg src={Fondo}/>
      </HeroBg>
      <HeroContent>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesH1>¿QUIÉNES SOMOS?</ServicesH1>
          <ServicesP>Somos una empresa que pone a su disposición el mejor talento humano para la atención de sus necesidades tecnológicas a nivel nacional. Nuestro servicio integral en mantenimientos preventivos, correctivos y renovación tecnológica, le asegura la mejor experiencia de principio a fin.
          </ServicesP>
          <ServicesH2>MISIÓN</ServicesH2>
          <ServicesP>Brindamos soluciones eficientes a la plataforma tecnológica de nuestros clientes, con un equipo humano calificado e innovador que le asegura una integración eficiente entre la infraestructura tecnológica y los servicios TI asociados.
         </ServicesP>
          <ServicesH2>VISIÓN PARA EL 2030</ServicesH2>
          <ServicesP>Ser reconocidos en el mercado como el mejor aliado en la integración de servicios de tecnología, con la mayor cobertura a nivel nacional.
          </ServicesP>
          <HeroBtnWrapper>
          <Button to='contacto' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
          Contáctenos{hover ? <ArrowForward /> : <ArrowRight/>}
          </Button>
        </HeroBtnWrapper>

        </ServicesCard>
        <ServicesCard1>
        <ServicesIcon src={Icon1}/>
        </ServicesCard1>

      </ServicesWrapper>

      </HeroContent>

    </ServicesContainer>
  )
}

export default Data
