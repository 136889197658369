import React from 'react'
import Imagenes from './carusel'
import Fondo from '../../images/white.jpg'

import {ServicesContainer, ServicesWrapper, HeroBg, HeroContent, Bg, ServicesH1 } from './ServiceElements'

const Experiencia = () => {
  return (
    <ServicesContainer id='experiencia'>
      <Imagenes/>
      <HeroBg>
        <Bg src={Fondo}/>
      </HeroBg>
      <HeroContent>
        
        <ServicesWrapper>
          <ServicesH1>NUESTROS CLIENTES </ServicesH1>
          <Imagenes/>     
        </ServicesWrapper>
      </HeroContent>
    </ServicesContainer>
  )
}

export default Experiencia
