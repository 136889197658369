import styled from 'styled-components'
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'

export const ServicesContainer = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1200px) {
    height: 1300px;
  }

 `
export const ServicesWrapper = styled.div`
    max-width: 1600px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
  }

    @media screen and (max-width: 786px) {
      grid-template-columns: 1fr;
  }
`
export const ServicesCard = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: auto;
  padding:  20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
`
export const ServicesCard1 = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: auto;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }

`
export const ServicesIcon = styled.img`
  height: 90%;
  width: 100%;
  border-radius: 25% 10%;

`
export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 483px) {
    font-size: 1.5rem;
  }  
`
export const ServicesH2 = styled.h2`
  font-size: 1.5rem;
  margin-top: 20px;
  text-align: left;

`
export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: justify;
  margin-top: 10px;

`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`
export const HeroContent = styled.div`
  z-index: 3;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Bg = styled.img`
  height: 100%;
  width: 100%;
`
export const HeroBtnWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`



