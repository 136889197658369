import React, { Component } from "react";
import Slider from "react-slick";

import { Slide, ServicesIcon, CaruselContainer, } from "./ServiceElements";
import Icon1 from '../../images/001.png'
import Icon2 from '../../images/002.png'
import Icon3 from '../../images/003.png'
import Icon4 from '../../images/004.png'
import Icon5 from '../../images/005.png'
import Icon6 from '../../images/006.png'
import Icon7 from '../../images/007.png'
import Icon8 from '../../images/008.png'
import Icon9 from '../../images/009.png'
import Icon10 from '../../images/010.png'
import Icon11 from '../../images/011.png'
import Icon12 from '../../images/012.png'


class Imagenes extends Component {
  render() {    
    const settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: true,
      speed: 2500,
      autoplaySpeed: 1000,
      pauseOnHover: true,
      nextArrow: 0,
      prevArrow: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            infinite: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        }
      ]
    };
    return (
      
      <CaruselContainer>

      
        <Slider {...settings}>
            <Slide><ServicesIcon src= {Icon1}/></Slide>
            <Slide><ServicesIcon src= {Icon2}/></Slide>
            <Slide><ServicesIcon src= {Icon3}/></Slide>
            <Slide><ServicesIcon src= {Icon4}/></Slide>
            <Slide><ServicesIcon src= {Icon5}/></Slide>
            <Slide><ServicesIcon src= {Icon6}/></Slide>
            <Slide><ServicesIcon src= {Icon7}/></Slide>
            <Slide><ServicesIcon src= {Icon8}/></Slide>
            <Slide><ServicesIcon src= {Icon9}/></Slide>
            <Slide><ServicesIcon src= {Icon10}/></Slide>
            <Slide><ServicesIcon src= {Icon11}/></Slide>
            <Slide><ServicesIcon src= {Icon12}/></Slide>
        </Slider>

        </CaruselContainer>



    );
  }
}

export default Imagenes
