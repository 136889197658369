import React, { Component } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '600px',
  height: '400px',
};

const center = {
    lat: 4.661990113499477,
    lng: -74.13471107994327  
};

const marker = {
  lat: 4.661990113499477,
  lng: -74.13471107994327  
};


class Map extends Component {
  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyCuvJjh5raa5hAnJyGASLKkvBOHSXosIDw"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          Markers={marker}
          zoom={18}
          
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default Map
