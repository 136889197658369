import styled from 'styled-components'

export const ServicesContainer = styled.div`
  height: 2000px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  @media screen and (max-width: 1800px) {
    height: 2200px;
  }
  @media screen and (max-width: 1200px) {
    height: 2500px;
  }
  @media screen and (max-width: 1030px) {
    height: 2800px;
  }
  @media screen and (max-width: 935px) {
    height: 3000px;
  }
  @media screen and (max-width: 871px) {
    height: 3600px;
  }
  @media screen and (max-width: 710px) {
    height: 4000px;
  }
  @media screen and (max-width: 430px) {
    height: 4200px;
  }
  @media screen and (max-width: 388px) {
    height: 4500px;
  }
  @media screen and (max-width: 354px) {
    height: 4600px;
  }
  @media screen and (max-width: 326px) {
    height: 4700px;
  }

 `
export const ServicesWrapper = styled.div`
    max-width: 1800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    padding: 0px 15px;

    @media screen and (max-width: 871px) {
      grid-template-columns: 1fr;
  }
`
export const ServicesCard = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: auto;
  padding: 0px 45px;
  margin-top:10px;
  transition: all 0.2s ease-in-out;
  }
  @media screen and (max-width: 871px) {
    padding: 0px 5px;
  }
`
export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
  border-radius: 50%;
  box-shadow: 20px -60px 500px #d2e653;
  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor:pointer;}

`
export const ServicesH1 = styled.h1`
  font-size: 3rem;
  color: #d2e653;
  margin-top 50px;
  margin-bottom: 10px;

  @media screen and (max-width: 483px) {
    font-size: 2rem;
  }  
`
export const ServicesH2 = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 25px;
  text-align: center;  
`
export const ServicesP = styled.p`
  font-size: 1rem;
  color: #fff;
  text-align: justify;
  @media screen and (max-width: 575px) {
    font-size: 0.8rem;
  }
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`
export const HeroContent = styled.div`
  height: 1500px;
z-index: 3;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Bg = styled.img`
  height: 100%;
  width: 100%;
`