import styled from 'styled-components'

export const ServicesContainer = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media screen and (max-width: 768px) {
    height: 1100px;
  }
  @media screen and (max-width: 480px) {
    height: 1300px;
  }
 `
export const ServicesWrapper = styled.div`
    width: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;


    @media screen and (max-width: 935px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
  }
`
export const ServicesCard = styled.div`
  background: #0d2631;
  padding: 25px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 400px;
  display: grid;
  grid-template-rows: 1fr 1fr;

  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;

`
export const ServicesCard2 = styled.div`
  background: #0d2631;
  display: flex;
  align-items: center;
  height: 400px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
  @media screen and (max-width: 500px) {
    width: 400px;
  }
  @media screen and (max-width: 420px) {
    width: 300px;
  }

`
export const ServicesH1 = styled.h1`
  font-size: 3rem;
  color: #fff;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 2fr;


  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const ServicesP = styled.p`
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Bg = styled.img`
  height: 100%;
  width: 100%;
`
export const Formul = styled.form`
   width: 100%;
`
export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &:hover {
    transform: scale(1.12);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
`
export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;  
`
export const InputSubmit = styled.input`
  background-color: #cdda56;
  border: none;
  width: 100%;
  color: white;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.5rem;

`
export const ServiceInput = styled.input`
  font-family: inherit;
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 5px;
  width: 100%;
  border-radius: 6px;
  padding: 2px;
`
export const ServiceText = styled.input`
  font-family: inherit;
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 5px;
  width: 100%;
  padding: 2px 2px 30px;
`