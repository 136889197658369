import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SocialIconLink } from './SidebarElements'
import { MdAttachEmail } from 'react-icons/md'

const Sidebar = ({isOpen,toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='services' onClick={toggle}>Servicios</SidebarLink>
          <SidebarLink to='data' onClick={toggle}>¿Quienes somos?</SidebarLink>
          <SidebarLink to='experiencia' onClick={toggle}>Nuestros Clientes</SidebarLink>
          <SidebarLink to='contacto' onClick={toggle}>Contacto</SidebarLink>
          <SideBtnWrap>
            <SocialIconLink href='https://micorreo.claro.com.co/#/mail/list/msg' target='_blank' arial-label='Linkedin'>
              <MdAttachEmail />
            </SocialIconLink>
          </SideBtnWrap>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
