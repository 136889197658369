import React from 'react'

const Cont = () => {
  return (
    <div>
        <div >
        <img src="https://counter10.optistats.ovh/private/freecounterstat.php?c=8behbzz9f3b4hxncgd1rmtn5tyepsdjc" border="0" title="hit counter" alt="hit counter"/>
        </div>
    </div>

  )
}

export default Cont