import styled from 'styled-components'

export const ServicesContainer = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    height: 800px;
  }
  @media screen and (max-width: 480px) {
    height: 600px;
  }
 `
export const CaruselContainer = styled.div`
max-width: 100%;
`
export const Slide = styled.div`
background-color: transparent;
color: darkgreen;
cursor: grab;
`
export const ServicesWrapper = styled.div`
    max-width: 1500px;
    @media screen and (max-width: 1550px) {
      width: 1400px;
    }
    @media screen and (max-width: 1450px) {
      width: 1200px;
    }
    @media screen and (max-width: 1256px) {
      width: 1000px;
    }
    @media screen and (max-width: 1024px) {
      width: 850px;
    }
    @media screen and (max-width: 900px) {
      width: 600px;
    }
    @media screen and (max-width: 650px) {
      width: 380px;
    }
    @media screen and (max-width: 400px) {
      width: 280px;
    }
`
export const ServicesIcon = styled.img`
  height: 100%;
  width: 100%;
  }
`
export const ServicesH1 = styled.h1`
  font-size: 2rem;
  color: #4c6d84;
  text-align: center;
  margin-bottom: 80px;


  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Bg = styled.img`
  height: 100%;
  width: 100%;
`