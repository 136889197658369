import React from 'react'
import {ServicesContainer, ServicesWrapper, ServicesCard, ServicesCard2, HeroBg, HeroContent, Bg, ServicesH1, SocialIcons, SocialIconLink, ServicesP} from './ServiceElements'
import Fondo from '../../images/green.jpg'
import Formulario from './form'
import Map from './Map'

import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'



const Contacto = () => {
  return (
    
    <ServicesContainer id='contacto'>
      <HeroBg>
        <Bg src={Fondo}/>
      </HeroBg>
      <HeroContent>
        <ServicesH1>Contacto</ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <Formulario/>
            <ServicesP>Calle 18 #86-55 Bogotá D.C.<br/>Tel. 3102583231 - 3232093162</ServicesP>
            <SocialIcons>
                <SocialIconLink href='https://www.facebook.com/profile.php?id=100086901615688' target='_blank' arial-label='Facebook'>
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink href='https://www.instagram.com/coberturatotal_ti/' target='_blank' arial-label='Instagram'>
                  <FaInstagram/>
                </SocialIconLink>
                <SocialIconLink href='https://www.linkedin.com/in/cobertura-total-en-servicios-ti-25a440254/' target='_blank' arial-label='Linkedin'>
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
          </ServicesCard>
          <ServicesCard2>
            <Map/>
          </ServicesCard2>
        </ServicesWrapper>
      </HeroContent>
      

    </ServicesContainer>
  )
}

export default Contacto
