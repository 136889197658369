import React, {useState} from 'react'
import Contacto from '../components/Contacto';
import Experiencia from '../components/Experiencia';
import Footer from '../components/Footer';
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Pie from '../components/Pie';
import Data from '../components/Data';
import Services from '../components/Services';
import Sidebar from '../components/SideBar'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Hero />
      <Pie />
      <Services />
      <Data />
      <Experiencia />
      <Contacto />
      <Footer />
    </>
  )
}

export default Home
