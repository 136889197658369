import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { FooterContainer, FooterWrap, FooterLinkWrapper, FooterLinkItems, FooterLinkContainer, FooterLinkTitle, FooterLink, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights, } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';
import Cont from './Cont';


const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
        <FooterWrap>
          <FooterLinkContainer>
            <FooterLinkWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>SERVICIOS</FooterLinkTitle>
                  <FooterLink to='/'>MANTENIMIENTO PREVENTIVO</FooterLink>
                  <FooterLink to='/'>MANTENIMIENTO CORRECTIVO</FooterLink>
                  <FooterLink to='/'>RENOVACIÓN TECNOLÓGICA</FooterLink>
                  <FooterLink to='/'>MANOS REMOTAS</FooterLink>
              </FooterLinkItems>
            </FooterLinkWrapper>

            <FooterLinkWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>SERVICIOS</FooterLinkTitle>
                  <FooterLink to='/'>SOPORTE DE PRIMER NIVEL</FooterLink>
                  <FooterLink to='/'>SOPORTE DE SEGUNDO NIVEL</FooterLink>
                  <FooterLink to='/'>MIGRACIÓN DE DATOS</FooterLink>
                  <FooterLink to='/'>SERVICIO DE NETWORKING</FooterLink>
              </FooterLinkItems>
            </FooterLinkWrapper>
          </FooterLinkContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to='/' onClick={toggleHome}>
                CTS-TI
              </SocialLogo>
              <WebsiteRights><Cont/></WebsiteRights>
              <SocialIcons>
                <SocialIconLink href='https://www.facebook.com/profile.php?id=100086901615688' target='_blank' arial-label='Facebook'>
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink href='https://www.instagram.com/coberturatotal_ti/' target='_blank' arial-label='Instagram'>
                  <FaInstagram/>
                </SocialIconLink>
                <SocialIconLink href='https://www.linkedin.com/in/cobertura-total-en-servicios-ti-25a440254/' target='_blank' arial-label='Linkedin'>
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>

    </FooterContainer>
  )
}

export default Footer
