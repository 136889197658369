import styled from 'styled-components'

export const ServicesContainer = styled.div`
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media screen and (max-width: 871px) {
    height: 1200px;
  }

  @media screen and (max-width: 595px) {
    height: 1400px;
  }
  @media screen and (max-width: 385px) {
    height: 1600px;
  }
  @media screen and (max-width: 325px) {
    height: 1800px;
    padding: 0px;
  }
 `
export const ServicesWrapper = styled.div`
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;

    @media screen and (max-width: 871px) {
      grid-template-columns: 1fr;
  }
`
export const ServicesCard = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 530px;
  padding:  35px;
  box-shadow: 50px 1px 50px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
  @media screen and (max-width: 871px) {
    height: auto;
  }

`
export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
  border-radius: 50%;
`
export const ServicesH2 = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`
export const ServicesH3 = styled.h2`
  font-size: 0.8rem;
  margin-bottom: 10px;
  text-align: center;

  
`
export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: justify;
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Bg = styled.img`
  height: 100%;
  width: 100%;
`